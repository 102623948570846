import React from 'react'
import cascading from '../assests/css/footerstyle.module.css';
import { FaTelegramPlane, FaDiscord } from "react-icons/fa";
import logo from '../assests/images/bitdealz/logo.png';
import minilogo from '../assests/images/logomini.png';

import media1 from '../assests/images/media1.png';
import mediahover1 from '../assests/images/mediahover1.png';

import media2 from '../assests/images/media2.png';
import mediahover2 from '../assests/images/mediahover2.png';

import media3 from '../assests/images/media3.png';
import mediahover3 from '../assests/images/mediahover3.png';

import media4 from '../assests/images/media4.png';
import mediahover4 from '../assests/images/mediahover4.png';

import { Link } from 'react-router-dom';
import { IoMdMail } from "react-icons/io";
import { FaFile } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";
import { FaTwitter } from "react-icons/fa";


import { CHAINS, FRONT_URL,ANALYTICS_URL } from '../config/env';


export default function Footer() {
  return (
    <>
      <div className={`${cascading.footersec}`}>
        <div className={`container`}>
          <div className={`${cascading.flexfooterbottom}`}>
          <img src={logo} alt='logo' className={`img-fluid ${cascading.logoimg}`}/>
          <div>
          <div className={`${cascading.dflex} ${cascading.dflexfootericon}`}>
                      {/* <ul>
                        <li> */}
                      <Link to="https://discord.com/invite/uDJsugfcFU" target='_blank'>
                       <FaDiscord />
                      </Link>
                      {/* </li>
                        <li> */}
                      <Link to="https://t.me/+Kx8h3cwRHh5kYmNk" target='_blank' className={`${cascading.telegramlink}`}>
                        <FaTelegramPlane /> 
                       
                      </Link>
                      <Link to="/swap" className={`${cascading.pageroute} ${cascading.mobilemenualign}`}>Exchange</Link>
                    <Link to="/farms" className={`${cascading.pageroute} ${cascading.mobilemenualign}`}>Farms</Link>
                    <Link to="/pools" className={`${cascading.pageroute} ${cascading.mobilemenualign}`}>Pools</Link>
                    <Link to="/about" className={`${cascading.pageroute} ${cascading.mobilemenualign}`}>About Us</Link>
                    <Link to="/whitepaper" className={`${cascading.pageroute} ${cascading.mobilemenualign}`}>Whitepaper</Link>
                  
                     
                    </div>

                   
                 
          </div>
          </div>
        
        

        </div>

      </div>

      <section className={`${cascading.copyfooter}`}>
        <div className="container">
          <p className={`${cascading.copob} text-center`}>Copyright © 2023 BitDealz Exchange - All Rights Reserved.</p>
          </div>
          </section>



    </>
  )
}
